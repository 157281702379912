import "src/components/HomePage/HomePageBanner/GenericAsiaBanner/GenericAsiaBanner.css.ts.vanilla.css!=!../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.13_@types+node@22.5.4_babel-plugin-macros@3.1.0_terser@5._3yvhuitm3y4blfzbynte2au3tq/node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/HomePage/HomePageBanner/GenericAsiaBanner/GenericAsiaBanner.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA+2SzY6CMBSF9zxFl7KoQVAU3Pgmk9JeoTPQklKFOOHdJ1ZJbOVnZj+79p7vNvec3vXHRgZdk98Y+vYQOpfQYcYVUM2lSNGVqBXGA7Pzj17vrYcrmJaaMMZFjrWsXT72jy9AJrWWlcvsLaaEs3aJg0UonhdvSGIPdjaDFTCGEvNaxQUe1zOjt5zpwpWokTJCv3IlL4JhXpEcXGrjUg2/QYqovIJyFAU1EJ0iIZ9HR69lw8f+IbTt5sbuSx+VpVRuU2A3FUufF9k8N3xbcA24qQmF+9itIrVFfRrqZWPuxstLJY5WmaQoCoK6s6s0RWG8dav7YbBMBjtxDXxbjuflw7yc2PLD86kCxglqqAIQiAiGVhXp8HMndvfBfePTsT1pfMr6lPkZ+5HvAqMBLEYwE8ID6CeC4GIIYr/9D8IEkSThX4NYfnQT/nbPlGzHsk2i0WyjbbyYbbyU7TtwWAKSMaD3+h8uNp56gQYAAA==\"}!../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.13_@types+node@22.5.4_babel-plugin-macros@3.1.0_terser@5._3yvhuitm3y4blfzbynte2au3tq/node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var background = '_1o0xsgzg';
export var backgroundColour = 'var(--_1o0xsgz0)';
export var backgroundImage = 'var(--_1o0xsgz1)';
export var backgroundPosition = 'var(--_1o0xsgz2)';
export var containerDirection = 'var(--_1o0xsgz5)';
export var containerLayout = '_1o0xsgzd';
export var contentGutter = '_1o0xsgze';
export var contentPaddingBottom = 'var(--_1o0xsgz7)';
export var contentPaddingLeft = 'var(--_1o0xsgz8)';
export var contentPaddingRight = 'var(--_1o0xsgz9)';
export var contentPaddingTop = 'var(--_1o0xsgz6)';
export var image = '_1o0xsgzf';
export var imageHeightVar = 'var(--_1o0xsgza)';
export var imageMinHeightVar = 'var(--_1o0xsgzb)';
export var imageWidthVar = 'var(--_1o0xsgzc)';
export var nowrap = '_1o0xsgzi';
export var responsive = '_1o0xsgzj';
export var title = '_1o0xsgzh';
export var titleFontSize = 'var(--_1o0xsgz4)';
export var titleTopPadding = 'var(--_1o0xsgz3)';